/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 27번째 에러 로그에서는 NDC, 뉴스레터, 해커스 크라우드 펀딩 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ndc.nexon.com/main"
  }, "NDC-NEXON DEVELOPERS CONFERENCE")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://supercell.com/en/games/brawlstars/"
  }, "Brawl Stars × Supercell")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://supercell.com/en/"
  }, "Supercell")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.reuters.com/article/us-supercell-m-a-tencent-holdings/chinas-tencent-buys-clash-of-clans-maker-supercell-for-8-6-billion-idUSKCN0Z716E"
  }, "China's Tencent buys 'Clash of Clans' maker Supercell for $8.6 billion - Reuters")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/goods/52944918"
  }, "카오스 멍키 - YES24")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.sgwf2018.com/"
  }, "성남 게임월드 페스티벌 2018")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.gstar.or.kr/"
  }, "G-STAR 2018")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tesla.com/modelx"
  }, "Model X | Tesla")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.washingtonpost.com/business/2019/04/22/tesla-investigates-model-s-explosion-shanghai/?noredirect=on&utm_term=.f7bf0dbd1b88"
  }, "Tesla investigates Model S explosion in Shanghai - The Washington Post")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/AWSonAir/status/1118720032127610882"
  }, "AWSonAir on Twitter: \"New #AWSDeepRacer League high score ...")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hackster.io/contests/alexa-reinvent"
  }, "AWS re:Invent Alexa Skill Contest - Hackster.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bloter.net/archives/325608"
  }, "택시 호출도 로밍으로…’카카오T 재팬택시’ 출시")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.uber.com/kr/ko/"
  }, "Uber - 지금 차량 서비스를 이용하거나 차량 운행으로 수익을 올리세요")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tadatada.com/"
  }, "타다 : TADA")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.morningbrew.com/"
  }, "Morning Brew")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://newneek.co/"
  }, "뉴닉 Newneek | 밀레니얼을 위한 시사메일링")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://publy.us11.list-manage.com/subscribe?u=8e469b932eaa730d3a89bc869&id=f64c75daf2"
  }, "What we're reading")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://mediagotosa.com/536"
  }, "퍼블리의 뉴스 서비스와 뉴스 생태계의 재구성")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/23328889"
  }, "단독 - 싸이월드, 뉴스 선별 앱 '큐' 서비스 잠정 중단")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.smartnews.com/ja/"
  }, "SmartNews")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.imbc.com/broad/radio/fm/economy/index.html"
  }, "이진우의 손에 잡히는 경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://daily-devblog.com/"
  }, "Daily DevBlog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubyweekly.com/"
  }, "Ruby Weekly")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cooperpress.com/"
  }, "Cooperpress: Email Newsletters for Developers")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.econote.co.kr/default.asp"
  }, "예병일의 경제노트 - 연결된 우리, 따뜻한 세상")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.godowon.com/"
  }, "고도원의 아침편지")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.bloter.net/archives/281259"
  }, "네이버, AI 기반 콘텐츠 큐레이션 서비스 ‘디스코’ 출시")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.macrumors.com/2016/04/29/twitter-news-category-app-store-visibility/"
  }, "Twitter Moves to 'News' Category in App Store to Boost Visibility - MacRumors")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.snek.ai/home#economy"
  }, "스넥(SNEK) - 나만의 투자 정보")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bbc.com/news/uk-47891737"
  }, "Julian Assange: Wikileaks co-founder arrested in London - BBC News")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tumblbug.com/hackers"
  }, "세 번 절판하고 네 번째 hackers를 준비 하기 :: 텀블벅")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/insightbook/status/1120574636603744256"
  }, "INSIGHTBOOK on Twitter: \"내심 한빛이 그냥 절판하길 바랐는데... ㅎㅎ\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/Goods/2256?scode=032&OzSrank=1"
  }, "해커 그 광기와 비밀의 기록 - YES24")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/Margin-Safety-Risk-Averse-Strategies-Thoughtful/dp/0887305105/ref=sr_1_1?keywords=safety+margin&qid=1556494819&s=gateway&sr=8-1"
  }, "Amazon.com: Margin of Safety: Risk-Averse Value Investing Strategies for the Thoughtful Investor (9780887305108): Seth A. Klarman: Gateway")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://insights.stackoverflow.com/survey/2019?utm_source=Iterable&utm_medium=email&utm_campaign=dev-survey-2019"
  }, "Stack Overflow Developer Survey 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/nekobato/status/1113981039234281472"
  }, "(일본어) 猫鳩柔工業 on Twitter: \"슬랙이 다운되서 옆 사람이랑 커뮤니케이션할 수 없어요\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=50265443"
  }, "알라딘: 리모트 - 사무실 따윈 필요 없어!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.joelonsoftware.com/2000/08/09/the-joel-test-12-steps-to-better-code/"
  }, "The Joel Test: 12 Steps to Better Code – Joel on Software")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=48591045"
  }, "알라딘: 메이크 스페이스 - 창의와 협력을 이끄는 공간 디자인")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://publy.co/set/327"
  }, "오피스 디자인 가이드 - 일잘러를 위한 오피스는 다르다 - PUBLY")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%B2%A4%EC%A0%A0"
  }, "벤젠 - 위키백과, 우리 모두의 백과사전")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
